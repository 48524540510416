import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, CircularProgress , Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, LinearProgress, Tooltip, Box, Typography, Select, MenuItem, InputLabel, FormControl, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help'; // Importing the HelpIcon
import RefreshIcon from '@mui/icons-material/Refresh';
import { MonetizationOn, Euro, CurrencyPound } from '@mui/icons-material';

const UpgradeDialog = ({ open, onClose }) => {
  const [limits, setLimits] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hoveredLimit, setHoveredLimit] = useState(null); // State to store the hovered limit
  const [timeLeft, setTimeLeft] = useState(0);
  const [stripeLink, setStripeLink] = useState(''); // State for storing the Stripe link
  const [currency, setCurrency] = useState('usd'); // State for selected currency
  const [isUpgrading, setIsUpgrading] = useState(false); // Track the loading state for upgrading

  const features = [
      { name: 'Screenshots', currentLimit: '1000 / Daily', lifetimeLimit: '20,000 / Daily', value: 'screenshot', description: 'Retrieve screenshots from sandbox scans' },
    { name: 'Results', currentLimit: '500 / Daily', lifetimeLimit: '20,000 / Daily', value: 'results', description: 'Retrieve sandbox results' },
    { name: 'Hunt Queries', currentLimit: '500 / Daily', lifetimeLimit: '10,000 / Daily', value: 'hunt', description: 'Hunt IPs, Domains, sha56, strings and more' },
    { name: 'AI-Messages', currentLimit: '500 / Daily', lifetimeLimit: '10,000 / Daily', value: 'message', description: 'Retrieve messages from AI-Analyst' },
    { name: 'Sandbox', currentLimit: '100 / Daily', lifetimeLimit: '2,000 / Daily', value: 'sandbox', description: 'Submit domains or urls to sandbox' },
    { name: 'AI-Analyst', currentLimit: '25 / Daily', lifetimeLimit: '500 / Daily', value: 'analyst', description: 'Integrate your plugins and speak to them with AI-Analyst' },
  ];



  const prices = {
      USD: "$129",
      EUR: "€119",
      GBP: "£89"
    };

  const accessToken = localStorage.getItem('accessToken');
    const lifetimePrice = "$199"; // Set lifetime membership price here
    const lifetimeExpiryDate = new Date("2024-12-31T23:59:59Z"); //

  const fetchLimits = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://community.webamon.co.uk/limits', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`, // Include the access token in the Authorization header
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setLimits(data.limits); // Set the received data to state
      } else {
        console.error('Failed to fetch limits:', response.status);
      }
    } catch (error) {
      console.error('Error fetching limits:', error);
    } finally {
      setLoading(false);
    }
  };



  const fetchStripeLink = async () => {
    setIsUpgrading(true); // Set upgrading state to true when initiating the upgrade
    try {
      const response = await fetch('https://community.webamon.co.uk/upgrade', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          currency: currency.toLowerCase(),
        }),
      });

      if (response.ok) {
        const data = await response.json();
        setStripeLink(data.stripeLink);
        window.open(data.stripeLink, '_blank');
      } else {
        console.error('Failed to fetch Stripe link');
      }
    } catch (error) {
      console.error('Error fetching Stripe link:', error);
    } finally {
      setIsUpgrading(false); // Reset upgrading state after fetching is complete
    }
  };

  useEffect(() => {
    if (open) {
      fetchLimits();
    }
  }, [open, accessToken]); // Dependency array now includes `accessToken`

  // Helper function to calculate the percentage of usage for the progress bar
  const calculateUsagePercentage = (current, max) => {
    return (current / max) * 100;
  };

  // Helper function to calculate the time left to midnight UTC
  const calculateTimeLeft = () => {
    const now = new Date();
    const midnight = new Date();
    midnight.setUTCHours(24, 0, 0, 0); // Set to midnight UTC
    return midnight - now;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000); // Update every second

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

 const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };


  const handleUpgradeClick = async () => {
    await fetchStripeLink(); // Fetch the Stripe link when the user clicks "Upgrade Now"
  };

  // Format the remaining time as hours, minutes, seconds
  const formatTime = (milliseconds) => {
    const hours = Math.floor(milliseconds / 1000 / 60 / 60);
    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);
    const seconds = Math.floor((milliseconds / 1000) % 60);
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"

      fullWidth
  PaperProps={{
    style: {
backgroundColor: '#191b2d',
      color: 'white', // Text color
      height: '85vh',
      padding: 0, // Remove padding from the dialog itself
      boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)', // Soft shadow for a 3D effect
            overflow: 'hidden', // Remove any internal scrolls
    },
  }}
>
  <DialogContent
    sx={{
      padding: 1.5, // Remove padding from DialogContent
      overflowX: 'hidden',
    }}
  >
    <TableContainer
      component={Paper}
      sx={{
backgroundColor: '#191b2d',
        borderRadius: 0, // Remove border radius
        padding: 0, // Remove padding
        margin: 0, // Remove margin
        boxShadow: 'none', // Remove shadow if any

      }}
    >
          <Table>
<TableHead>
  <TableRow>
    <TableCell sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.4vw' }}>
      Features
    </TableCell>
    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.4vw' }}>
      Current Plan
      <IconButton onClick={fetchLimits} sx={{ color: 'white', marginLeft: '0.5vw', marginBottom: '0.4vw'  }}>
        <RefreshIcon />
      </IconButton>
    </TableCell>
    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.8vw' }}>
      Lifetime Membership
         <Tooltip
            title="Limited Offer. Your support drives our progress towards Democratizing Threat Intelligence and supplying actionable defense for everyone."
            arrow
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 10], // Adjust tooltip positioning
                  },
                },
              ],
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '20px', // Set the font size for the tooltip text
                  backgroundColor: '#1f2234', // Optional: Adjust tooltip background color
                  color: 'white', // Optional: Adjust text color if needed
                },
              },
            }}
          >
            <HelpIcon
              sx={{
                fontSize: '20px',
                marginLeft: '8px',
                cursor: 'pointer',
                '&:hover': {
                  color: '#39FF14', // Neon green color on hover for the icon
                },
              }}
            />
          </Tooltip>

    </TableCell>
  </TableRow>
</TableHead>


            <TableBody>

              {loading ? (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    <LinearProgress sx={{ marginTop: '1vw', height: '2vw' }}  />
                  </TableCell>
                </TableRow>
              ) : (
                features.map((feature, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ color: '#ffffff', fontWeight: 'bold', fontSize: '1.8vh' }}>
                      {feature.name}
                      <Tooltip
                        title={feature.description}
                        arrow
                        PopperProps={{
                          modifiers: [
                            {
                              name: 'offset',
                              options: {
                                offset: [0, 10], // Adjust tooltip positioning
                              },
                            },
                          ],
                        }}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: '20px', // Set the font size for the tooltip text
                              backgroundColor: '#1f2234', // Optional: Adjust tooltip background color
                              color: 'white', // Optional: Adjust text color if needed
                            },
                          },
                        }}
                      >
                        <HelpIcon
                          sx={{
                            fontSize: '20px',
                            marginLeft: '8px',
                            cursor: 'pointer',
                            '&:hover': {
                              color: '#39FF14', // Neon green color on hover for the icon
                            },
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center" sx={{ color: '#ffffff', fontSize: '1.8vh' }}>
                      {limits[feature.value]?.max} / Daily

                      {limits && limits[feature.value] && (
                        <Tooltip
                          title={`Current usage: ${limits[feature.value]?.current} / ${limits[feature.value]?.max}`}
                          arrow
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, 10], // Adjust tooltip positioning
                                },
                              },
                            ],
                          }}
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: '20px', // Set the font size for the tooltip text
                                backgroundColor: '#1f2234', // Optional: Adjust tooltip background color
                                color: 'white', // Optional: Adjust text color if needed
                              },
                            },
                          }}
                        >
                          <div
                            onMouseEnter={() => setHoveredLimit(feature.value)}
                            onMouseLeave={() => setHoveredLimit(null)}
                          >
                            <LinearProgress
                              variant="determinate"
                              value={calculateUsagePercentage(limits[feature.value]?.current, limits[feature.value]?.max)}
                              sx={{
                                marginTop: '8px',
                                height: '20px', // Progress bar height
                                backgroundColor: '#39424e',
                              }}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="center" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.8vh' }}>
                      {feature.lifetimeLimit}
                    </TableCell>
                  </TableRow>
                ))
              )}
{[

  {
    name: 'Lifetime Data Access',
    currentPlan: '✗',
    lifetimeMembership: '✓',
    description: 'Lifetime access to your data on the platform without renewal fees'
  },

  // Add more static features as needed
].map((feature, index) => (
  <TableRow key={`static-${index}`}>
    <TableCell sx={{ color: '#ffffff', fontWeight: 'bold', fontSize: '1.8vh' }}>
      {feature.name}
      <Tooltip
        title={feature.description}
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 10], // Adjust tooltip positioning
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '20px', // Set the font size for the tooltip text
              backgroundColor: '#1f2234', // Optional: Adjust tooltip background color
              color: 'white', // Optional: Adjust text color if needed
            },
          },
        }}
      >
        <HelpIcon
          sx={{
            fontSize: '20px',
            marginLeft: '8px',
            cursor: 'pointer',
            '&:hover': {
              color: '#39FF14', // Neon green color on hover for the icon
            },
          }}
        />
      </Tooltip>
    </TableCell>
    <TableCell align="center" sx={{ color: 'red', fontSize: '1.6vw', fontWeight: 'bold' }}>
      {feature.currentPlan}
    </TableCell>
    <TableCell align="center"sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.6vw' }}>
      {feature.lifetimeMembership}
    </TableCell>
  </TableRow>

))}
<TableRow>
  <TableCell sx={{ color: '#ffffff', fontWeight: 'bold', fontSize: '1.2vw' }}>Upgrade to Lifetime</TableCell>
  <TableCell align="center" colSpan={2}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center vertically within the cell
        gap: 2,
      }}
    >
      <RadioGroup
        value={currency}
        onChange={handleCurrencyChange}
        sx={{
          display: 'flex',
          flexDirection: 'row', // Arrange radio buttons horizontally
          alignItems: 'center', // Center radio group within the box
          gap: 2, // Add space between the currency options
        }}
      >
        <FormControlLabel
          value="USD"
          control={<Radio icon={<MonetizationOn sx={{ color: "white" }} />} checkedIcon={<MonetizationOn sx={{ color: "black" }} />} />}
          label={`${prices.USD}`}
          sx={{ fontSize: '1.2vw', color: 'white' }}
        />
        <FormControlLabel
          value="EUR"
          control={<Radio icon={<Euro sx={{ color: "white" }} />} checkedIcon={<Euro sx={{ color: "black" }} />} />}
          label={`${prices.EUR}`}
          sx={{ fontSize: '1.2vw', color: 'white' }}
        />
        <FormControlLabel
          value="GBP"
          control={<Radio icon={<CurrencyPound sx={{ color: "white" }} />} checkedIcon={<CurrencyPound sx={{ color: "black" }} />} />}
          label={`${prices.GBP}`}
          sx={{ fontSize: '1.2vw', color: 'white' }}
        />
      </RadioGroup>



      <Button
        onClick={handleUpgradeClick}
        variant="contained"
        sx={{
          backgroundImage: 'linear-gradient(135deg, #4a90e2, #56CCF2)',
          color: '#ffffff',
          padding: '12px 24px',
          fontWeight: 'bold',
          fontSize: '1rem',
          textTransform: 'uppercase',
          borderRadius: '8px',
          alignSelf: 'center', // Center the button in the row
          '&:hover': {
            backgroundImage: 'linear-gradient(135deg, #6d28d9, #ec4899)',
            boxShadow: '0px 4px 10px rgba(74, 144, 226, 0.5)',
          },
        }}
      >
        {isUpgrading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Upgrade Now'}
      </Button>
    </Box>
  </TableCell>
</TableRow>



            </TableBody>
          </Table>
        </TableContainer>

      </DialogContent>

    </Dialog>
  );
};

export default UpgradeDialog;
