// SidebarNav.js
import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Badge
} from '@mui/material';
import {signOut} from '../auth/authUtils'
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat'; // Icon for flows
import InfoIcon from '@mui/icons-material/Info';
import IntegrationIcon from '@mui/icons-material/Extension';
import ChatIcon from '@mui/icons-material/Chat'; // Icon for Threat-AI
import InsightsIcon from '@mui/icons-material/Insights'; // Icon for Intelligence
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Import icon for sign out
import HistoryIcon from '@mui/icons-material/History'; // Icon for Scans
import ScansDialog from '../dialogs/ScansDialog'; // Import the ScansDialog component
// Import each integration dialog and components
import SlackIntegrationDialog from '../integrations/SlackIntegrationDialog';
import GitHubIntegrationDialog from '../integrations/GitHubIntegrationDialog';
import JiraIntegrationDialog from '../integrations/JiraIntegrationDialog';
import VirustotalIntegrationDialog from '../integrations/VirustotalIntegrationDialog';
import AlienvaultIntegration from '../integrations/AlienvaultIntegrationDialog';
import CirclIntegrationDialog from '../integrations/CirclIntegrationDialog';
import GoogleSafeIntegrationDialog from '../integrations/GoogleSafeIntegrationDialog';
import GreynoiseIntegrationDialog from '../integrations/GreynoiseIntegrationDialog';
import HudsonRockIntegrationDialog from '../integrations/HudsonRockIntegrationDialog';
import NewsAPIIntegrationDialog from '../integrations/NewsAPIIntegrationDialog';
import WhoisXMLIntegrationDialog from '../integrations/WhoisXMLIntegrationDialog';
import WebamonIntegrationDialog from '../integrations/WebamonIntegrationDialog';
import ThreatAIChat from '../ThreatAIChat';



const SidebarNav = () => {
  const [openIntegrations, setOpenIntegrations] = useState(false);
  const [openFlows, setOpenFlows] = useState(false);
  const [openIntelligence, setOpenIntelligence] = useState(false); // State for Intelligence panel
  const [integrationCounts, setIntegrationCounts] = useState({});
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [selectedFlow, setSelectedFlow] = useState(null); // State for selected flow
  const [openChat, setOpenChat] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [openNewFlowDialog, setOpenNewFlowDialog] = useState(false);
  const [openScansDialog, setOpenScansDialog] = useState(false); // State for Scans dialog
  const [scanFilterText, setScanFilterText] = useState(''); // Filter text for scans
  const integrationsRef = useRef(null);
    const [loading, setLoading] = useState(false); // Loading state for sign-out button

    const [integrationLoading, setIntegrationLoading] = useState(false);
  const flowsRef = useRef(null);
  const intelligenceRef = useRef(null);

  const icons = [
//    { label: "Intelligence", icon: <InsightsIcon />, onClick: () => setOpenIntelligence(!openIntelligence) }, // Intelligence button
    { label: "Threat-AI", icon: <ChatIcon />, onClick: () => setOpenChat(!openChat) },
//    { label: "Flows", icon: <TrendingFlatIcon sx={{ transform: 'rotate(-45deg)' }} />, onClick: () => setOpenFlows(!openFlows) },
//    { label: "Integrations", icon: <IntegrationIcon />, onClick: () => setOpenIntegrations(!openIntegrations) },
  ];

  const integrations = [
    { name: "Slack", key: "slack" },
    { name: "GitHub", key: "github" },
    { name: "Jira", key: "jira" },
    { name: "Elasticsearch", key: "elasticsearch" },
    { name: "Virustotal", key: "virustotal" },
    { name: "AlienVault", key: "alienvault" },
    { name: "Circl CVE", key: "circlcve" },
    { name: "Google Safe Browsing", key: "googlesafebrowsing" },
    { name: "Greynoise", key: "greynoise" },
    { name: "Hudson Rock", key: "hudsonrock" },
    { name: "News API", key: "newsapi" },
    { name: "Whois XML API", key: "whoisxml" },
    { name: "Webamon", key: "webamon" },
  ];


  const handleIntegrationClick = (integrationKey) => {
    setSelectedIntegration(integrationKey);
  };



  const handleCloseDialog = () => {
    setSelectedIntegration(null);
    setSelectedFlow(null);
  };

  // Close integrations, flows, or intelligence panel if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (integrationsRef.current && !integrationsRef.current.contains(event.target)) {
        setOpenIntegrations(false);
      }
      if (flowsRef.current && !flowsRef.current.contains(event.target)) {
        setOpenFlows(false);
      }
      if (intelligenceRef.current && !intelligenceRef.current.contains(event.target)) {
        setOpenIntelligence(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


useEffect(() => {
  const fetchIntegrationCounts = async () => {

    setIntegrationLoading(true);
    try {
      // Retrieve accessToken from local storage
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token not found');
      }

      const response = await fetch('https://community.webamon.co.uk/plugin', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch integration data');
      }

      const data = await response.json();

      // Parse data and count values for integrations
  const counts = integrations.reduce((acc, integration) => {
          const integrationData = data[integration.key];
          acc[integration.key] = integrationData
            ? Object.keys(integrationData).length
            : 0;
          return acc;
        }, {});
        setIntegrationCounts(counts);
    } catch (error) {
      console.error('Error fetching integration data:', error);
    } finally {
      setIntegrationLoading(false);
    }
  };

  fetchIntegrationCounts();
}, [openIntegrations]);




  const handleSignOut = async () => {
    setLoading(true);
    try {
      await signOut();
      // Handle successful sign-out and reset loading state
    } catch (error) {
      console.error("Error during sign out:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: 60,
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingTop: '20px',
        zIndex: 1300,
      }}
    >
      <Box>
        {icons.map((item, index) => (
          <Tooltip key={index} title={item.label} placement="right">
            <IconButton
              href={item.link}
              onClick={item.onClick}
              sx={{
                color: '#ffffff',
                marginBottom: '20px',
                transition: 'color 0.3s',
                '&:hover': {
                  color: '#56CCF2',
                },
              }}
            >
              {item.icon}
            </IconButton>
          </Tooltip>
        ))}
      </Box>


      {/* Integrations List */}
           <Tooltip title="Integrations" placement="right">
              <IconButton
                onClick={() => setOpenIntegrations(!openIntegrations)}
                sx={{
                  color: '#ffffff',
                  marginBottom: '20px',
                  transition: 'color 0.3s',
                  '&:hover': {
                    color: '#56CCF2',
                  },
                }}
              >
                <Badge
                  badgeContent={Object.values(integrationCounts).reduce(
                    (sum, count) => sum + count,
                    0
                  )}
                  color="primary"
                >
                  <IntegrationIcon />
                </Badge>
              </IconButton>
            </Tooltip>


            {openIntegrations && (
              <Box
                ref={integrationsRef}
                sx={{
                  width: 240,
                  position: 'absolute',
                  left: 60,
                  top: 0,
                  bottom: 0,
                  backgroundColor: '#191b2d',
                  color: '#ffffff',
                  padding: '10px',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                  overflowY: 'auto',
                  zIndex: 1250,
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="h6" gutterBottom sx={{ flexGrow: 1 }}>
                    Integrations
                  </Typography>
                  <Tooltip
                    title="Securely store your creds in AWS Key Management Service. Your keys are tied to your tenant-id which is added to your oauth jwt bearer token claims during account creation. All jwt signatures are verified for each api request."
                    placement="top"
                                PopperProps={{
                                  modifiers: [
                                    {
                                      name: 'offset',
                                      options: {
                                        offset: [0, 10], // Adjust tooltip positioning
                                      },
                                    },
                                  ],
                                }}
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: '20px', // Set the font size for the tooltip text
                                      backgroundColor: '#1f2234', // Optional: Adjust tooltip background color
                                      color: 'white', // Optional: Adjust text color if needed
                                    },
                                  },
                                }}
                  >
                    <IconButton sx={{ color: '#56CCF2' }}>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {integrationLoading ? (
                  <CircularProgress color="inherit" />
                ) : (
                  <List>
                    {integrations.map((integration) => (
                      <ListItem
                        button
                        key={integration.key}
                        onClick={() => handleIntegrationClick(integration.key)}
                      >
                        <ListItemText primary={integration.name} />
                        {integrationCounts[integration.key] > 0 && (
                          <Badge
                            badgeContent={integrationCounts[integration.key]}
                            color="secondary"
                          />
                        )}
                      </ListItem>
                    ))}
                  </List>
                )}
              </Box>
            )}


      {/* Scans Section */}
<Tooltip title="Scans" placement="right">
        <IconButton
          onClick={() => setOpenScansDialog(true)}
          sx={{
            color: '#ffffff',
            transition: 'color 0.3s',
            '&:hover': { color: '#56CCF2' },
            marginBottom: "30px"
          }}
        >
          <HistoryIcon />
        </IconButton>
      </Tooltip>

      {/* Scans Dialog - using the existing ScansDialog component */}
      <ScansDialog open={openScansDialog} onClose={() => setOpenScansDialog(false)} />




  <Box sx={{ marginTop: 'auto' }}>
    <Tooltip title="Sign Out" placement="right">
      <IconButton
        onClick={handleSignOut}
        sx={{
          color: '#ffffff',
          transition: 'color 0.3s',
          '&:hover': { color: '#e53935' },
          'marginBottom': '1rem'
        }}
      >
        {loading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <ExitToAppIcon />
        )}
      </IconButton>
    </Tooltip>
  </Box>





      {/* Render selected integration dialog */}
      {selectedIntegration === "slack" && (
        <SlackIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "github" && (
        <GitHubIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "jira" && (
        <JiraIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}




      {selectedIntegration === "virustotal" && (
        <VirustotalIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}


      {selectedIntegration === "alienvault" && (
              <AlienvaultIntegration open={true} onClose={handleCloseDialog} />
            )}
      {selectedIntegration === "circlcve" && (
        <CirclIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "googlesafebrowsing" && (
        <GoogleSafeIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "greynoise" && (
        <GreynoiseIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "hudsonrock" && (
        <HudsonRockIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "newsapi" && (
        <NewsAPIIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}


      {selectedIntegration === "whoisxml" && (
        <WhoisXMLIntegrationDialog open={true} onClose={handleCloseDialog} />
      )}
      {selectedIntegration === "webamon" && (
        <WebamonIntegrationDialog open={true} onClose={handleCloseDialog} />

)}
      {openChat && <ThreatAIChat open={openChat} onClose={() => setOpenChat(false)} />}




    </Box>
  );
};

export default SidebarNav;
