import React, { useState, useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { Dialog, Box, IconButton, TextField, Button, Typography, Divider, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import LoginDialog from './dialogs/LoginForm'; // Import your login dialog component
import UpgradeDialog from './dialogs/UpgradeDialog';
import ThreatConfigDialog from './dialogs/ThreatConfigDialog';
import SettingsIcon from '@mui/icons-material/Settings'; // Import Settings Icon


const WEBSOCKET_BASE_URL = "wss://c5ydem6wnl.execute-api.eu-west-1.amazonaws.com/production";

const ThreatAIChat = ({ open, onClose, initialData }) => {
  const [messages, setMessages] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false); // State to control login dialog visibility
  const [showSettingsDialog, setShowSettingsDialog] = useState(false); // State for settings dialog
  const [currentModel, setCurrentModel] = useState('assistant'); // State for the current model
  const models = ['assistant', 'red_team']; // List of available models

  const messagesEndRef = useRef(null); // Ref for the bottom of the messages container

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const examplePrompts = [
    'What are the latest threats?',
    'Analyze a suspicious IP address.',
    'Show me recent intel.',
  ];

  useEffect(() => {
    scrollToBottom();
  }, [messages]);






  // Load initial data if provided
  useEffect(() => {
    if (initialData) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: 'bot', text: `Here's the report ID you requested to start with: ${initialData}` },
      ]);
    }
  }, [initialData]);

  const websocketRef = useRef(null);

useEffect(() => {
  const accessToken = localStorage.getItem('accessToken'); // Retrieve the Bearer token

  if (!accessToken) {
    console.error('Bearer token is missing');
    return;
  }

  const subprotocolToken = `Bearer-token-${accessToken}`; // Ensure valid characters

  setLoading(true); // Set loading to true when the dialog is opened

  try {
    websocketRef.current = new WebSocket(WEBSOCKET_BASE_URL, [subprotocolToken]);

    websocketRef.current.onopen = () => {
      console.log('WebSocket connection opened');

      // Send initial message on WebSocket open
      const initialPayload = JSON.stringify({
        action: 'init',
        agent_type: currentModel,
        data: 'Tell me who you are and provide a detailed list of the tools/functions you can call and what they do',
      });
      websocketRef.current.send(initialPayload);
      console.log('Initial WebSocket message sent:', initialPayload);

      // Start sending periodic ping messages
      const pingInterval = setInterval(() => {
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
          const payload = JSON.stringify({ action: 'ping' });
          websocketRef.current.send(payload);
          console.log('Ping message sent');
        }
      }, 10000); // Ping every 20 seconds

      // Store the interval ID for cleanup
      websocketRef.current.pingInterval = pingInterval;
    };






websocketRef.current.onmessage = (event) => {
  const data = event.data;

  try {
    let parsedData = JSON.parse(data);

    // Skip specific messages
    const skipStrings = [
      "POST /inject HTTP/1.1",
      "UserWarning: Function 'fetch_credentials' is being overridden",
      "Listening for messages",
      "Response from calling tool",
      "Serving Flask app 'main'",
      "Starting container",
      "Loading - ",
      "Update docstring",
      "User (to Assistant)"
    ];

    // Check if parsedData contains any of the skip strings
    const shouldSkip = skipStrings.some((str) => typeof parsedData === "string" && parsedData.includes(str));

    if (shouldSkip) {
      console.log("Skipping message:", parsedData);
      return; // Do not process the message
    }

    // Remove the "Assistant (to User):" prefix if it exists
if (
  typeof parsedData === "string" &&
  (parsedData.startsWith("Assistant (to User):") || parsedData.includes("Next speaker"))
) {
  if (parsedData.startsWith("Assistant (to User):")) {
    parsedData = parsedData.replace("Assistant (to User):", "").trim();
  }
  setLoading(false);
}


    if (parsedData === "pong") {
      console.log("Pong received from server");
    } else {
      console.log("Message received from WebSocket:", parsedData);
      setMessages((prevMessages) => [
        ...prevMessages,
        { type: "bot", text: parsedData },
      ]);
    }
  } catch (error) {
    console.error("Error parsing WebSocket message:", error);
  }
};


    websocketRef.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    websocketRef.current.onclose = () => {
      console.log('WebSocket connection closed');

      // Clear the ping interval if it exists
      if (websocketRef.current.pingInterval) {
        clearInterval(websocketRef.current.pingInterval);
      }
    };
  } catch (error) {
    console.error('Error initializing WebSocket:', error);
  }

  // Cleanup on component unmount
  return () => {
    if (websocketRef.current) {
      // Clear the ping interval if it exists
      if (websocketRef.current.pingInterval) {
        clearInterval(websocketRef.current.pingInterval);
      }
      websocketRef.current.close();
    }
  };
}, [open, currentModel]); // Run this effect when the dialog opens


const handleSend = (message) => {
  const text = message !== undefined ? message : inputValue; // Use message or the input value
  // Always allow sending, even if the string is empty
  setMessages([...messages, { type: 'user', text }]);
  setInputValue('');
  setLoading(true);

  try {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      const payload = JSON.stringify({
        action: 'sendMessage',
        data: text,
      });
      websocketRef.current.send(payload);
      console.log('Message sent:', payload);
    } else {
      console.error('WebSocket is not open');
    }
  } catch (error) {
    console.error('Error sending message via WebSocket:', error);
  } finally {
    setLoading(false);
  }
};

  const handleCloseDialog = () => {
    try {
      if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
        const payload = JSON.stringify({
          action: 'dialogClosed',
          data: '$$close$$',
        });
        websocketRef.current.send(payload);
        console.log('Dialog close message sent:', payload);
      }
    } catch (error) {
      console.error('Error sending close message via WebSocket:', error);
    }

    // Call the onClose prop after handling WebSocket
    onClose();
  };


  const handlePromptClick = (prompt) => {
    handleSend(prompt);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        style: {
          backgroundColor: '#191b2d',
          color: '#ffffff',
          width: '1600px',
          height: '1700px',
          maxWidth: 'none',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {/* Right-Aligned Section */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: 'auto' }}>
        {/* Dropdown for Current Model */}
        <FormControl
          sx={{
            minWidth: 200,
            backgroundColor: '#2b2f44',
            borderRadius: '8px',
            boxShadow: 'none',
          }}
          variant="outlined"
        >
          <Select
            value={currentModel}
            onChange={(e) => setCurrentModel(e.target.value)}
            disableUnderline
            sx={{
              color: '#ffffff',
              backgroundColor: '#2b2f44',
              borderRadius: '8px',
              '& .MuiSelect-icon': {
                color: '#ffffff',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #4a90e2',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #4a90e2',
              },
            }}
          >
            {models.map((model, index) => (
              <MenuItem key={index} value={model}>
                {model}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Settings and Close Buttons */}
        <IconButton onClick={() => setShowSettingsDialog(true)} sx={{ color: '#ffffff' }}>
          <SettingsIcon />
        </IconButton>
        <IconButton onClick={onClose} sx={{ color: '#ffffff' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {showLoginDialog && <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} />}
      {showSettingsDialog && <ThreatConfigDialog open={showSettingsDialog} onClose={() => setShowSettingsDialog(false)} />}

      {/* Chat Display Area */}
<Box
  sx={{
    flexGrow: 1,
    padding: '20px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    backgroundColor: '#1f2235',
    position: 'relative', // For overlay positioning
  }}
>
  {loading && (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
      }}
    >
      <CircularProgress sx={{ color: '#ffffff' }} />
    </Box>
  )}

  <Box
    sx={{
      filter: loading ? 'blur(4px)' : 'none', // Apply blur when loading
      transition: 'filter 0.3s ease', // Smooth transition for the blur
    }}
  >
    {messages.map((msg, index) => (
      <Box
        key={index}
        sx={{
          display: 'flex',
          flexDirection: msg.type === 'user' ? 'row-reverse' : 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          alignSelf: msg.type === 'user' ? 'flex-end' : 'flex-start',
          maxWidth: '80%',
          gap: '8px',
          marginBottom: '8px',
        }}
      >
        <Box
          sx={{
            backgroundColor: msg.type === 'user' ? '#343b6f' : '#2b2f44',
            color: '#ffffff',
            padding: '12px 16px',
            borderRadius: '12px',
            fontSize: '1.1rem',
            wordWrap: 'break-word',
            flexGrow: 1,
          }}
        >
          {msg.type === 'bot' ? (
            <ReactMarkdown>
              {typeof msg.text === 'string' ? msg.text : JSON.stringify(msg.text)}
            </ReactMarkdown>
          ) : (
            msg.text
          )}
        </Box>
      </Box>
    ))}
    {/* Scroll anchor reference */}
    <div ref={messagesEndRef} />
  </Box>
</Box>





      <Divider sx={{ backgroundColor: '#343b6f' }} />

      {/* Example Prompts */}
      <Box sx={{ padding: '10px 20px', backgroundColor: '#1f2235' }}>
        <Typography variant="subtitle1" sx={{ color: '#56CCF2', marginBottom: '8px' }}>
          Try one of these:
        </Typography>
        <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap' }}>
          {examplePrompts.map((prompt, index) => (
            <Button
              key={index}
              variant="outlined"
              onClick={() => handlePromptClick(prompt)}
              sx={{
                color: '#ffffff',
                borderColor: '#56CCF2',
                textTransform: 'none',
                fontSize: '0.9rem',
                '&:hover': { borderColor: '#4a90e2', backgroundColor: '#2b2f44' },
              }}
            >
              {prompt}
            </Button>
          ))}
        </Box>
      </Box>

      <Divider sx={{ backgroundColor: '#343b6f' }} />

      {/* Input Area */}
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px 20px', backgroundColor: '#1f2235' }}>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Type your message..."
          variant="outlined"
          fullWidth
          multiline
          minRows={1}
          sx={{
            backgroundColor: '#2b2f44',
            borderRadius: '8px',
            input: { color: '#ffffff', fontSize: '1.1rem' },
            '& .MuiInputBase-input': { color: '#ffffff' },
            '& .MuiInputBase-input::placeholder': { color: '#ffffff', opacity: 1 },
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#56CCF2',
              },
              '&:hover fieldset': {
                borderColor: '#4a90e2',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#4a90e2',
              },
            },
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              handleSend();
            }
          }}
        />
        <IconButton onClick={() => handleSend()} sx={{ color: '#56CCF2', marginLeft: '10px', fontSize: '1.5rem' }}>
          <SendIcon fontSize="large" />
        </IconButton>
      </Box>
    </Dialog>
  );
};

export default ThreatAIChat;
