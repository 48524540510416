import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Box, Typography, Checkbox, FormControlLabel,
                                                                                                                             Select,
                                                                                                                             MenuItem,
                                                                                                                             FormControl,
                                                                                                                             InputLabel, Switch } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import apiTypeFieldsMapping from './apiTypeFieldsMapping';




const ThreatConfigDialog = ({ open, onClose }) => {
  const [name, setName] = useState('');
  const [endpoint, setEndpoint] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [selectedIntegration, setSelectedIntegration] = useState(null);
  const [checkedIntegrations, setCheckedIntegrations] = useState({}); // Track checkbox states
  const [agentArchitecture, setAgentArchitecture] = useState('webamon_assistant'); // Default architecture
    const [memoryEnabled, setMemoryEnabled] = useState(false); // Memory toggle state
  const [apiType, setApiType] = useState('OpenAI'); // State for API Type dropdown
  const [integrations, setIntegrations] = useState([]);
  const [dynamicFields, setDynamicFields] = useState({}); // Store dynamic fields from llm_config
  const [isEditable, setIsEditable] = useState(false); // Global edit toggle


  useEffect(() => {
    const fetchIntegrations = async () => {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        console.error('Access token is missing.');
        return;
      }

      try {
        const response = await fetch('https://community.webamon.co.uk/analyst/profile', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setIntegrations(data.response);
          console.log(data.response)
        } else {
          console.error('Failed to fetch integrations:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching integrations:', error);
      }
    };

    if (open) {
      fetchIntegrations();
    }
  }, [open]);


    const handleMemoryToggle = () => {
      setMemoryEnabled((prev) => !prev);
    };



useEffect(() => {
  if (open && !selectedIntegration) {
    const defaultIntegration = integrations.find((integration) => integration.llm_profile_name === 'webamon_assistant');
    if (defaultIntegration) {
      handleIntegrationClick(defaultIntegration);
    }
  }
}, [open, integrations, selectedIntegration]);


  const handleIntegrationClick = (integration) => {
    setSelectedIntegration(integration.llm_profile_name);
    setName(integration.llm_profile_name || '');

    // Extract and set dynamic fields from llm_config
    const config = integration.profile.llm_config || {};
    setDynamicFields(config);

    // Set default values for known fields
    setAgentArchitecture(integration.profile.agent_type || 'Assistant');
    setApiType(integration.apiType || 'OpenAI');
  };

  const toggleEditMode = () => {
    setIsEditable((prev) => !prev);
  };

const handleDynamicFieldChange = (field, value) => {
  setDynamicFields((prev) => {
    return {
      ...prev,
      [field]: value,
    };
  });
};


const renderFieldsForApiType = () => {
  const fields = apiTypeFieldsMapping[apiType] || [];
  return fields.map((field) => (
    <TextField
      key={field} // Ensure the key is unique
      label={field}
      variant="outlined"
      fullWidth
      value={dynamicFields[field.toLowerCase()] || ''} // Bind to the correct dynamic field
      onChange={(e) => handleDynamicFieldChange(field.toLowerCase(), e.target.value)} // Update only the specific field
      disabled={!isEditable}
      InputLabelProps={{
        style: { color: '#ffffff' },
      }}
      InputProps={{
        style: {
          color: '#ffffff',
          backgroundColor: isEditable ? '#2b2f44' : '#1f2235',
        },
      }}
      sx={{
        marginBottom: '16px',
        '& .MuiOutlinedInput-root fieldset': { borderColor: '#56CCF2' },
        '&:hover .MuiOutlinedInput-root fieldset': { borderColor: '#4a90e2' },
        '&.Mui-focused .MuiOutlinedInput-root fieldset': { borderColor: '#4a90e2' },
      }}
    />
  ));
};

  const handleCheckboxChange = (llm_profile_name) => {
    setCheckedIntegrations((prev) => ({
      ...prev,
      [llm_profile_name]: !prev[llm_profile_name],
    }));
  };

const handleSave = async () => {
  const accessToken = localStorage.getItem('accessToken');

  if (!accessToken) {
    console.error('Access token is missing.');
    alert('You are not logged in. Please log in and try again.');
    return;
  }

  const payload = {
    llm_profile_name: name,

        endpoint,
        api_key: apiKey,
        api_type: apiType,
        system_prompt: systemPrompt,
        ...dynamicFields,
      agent_type: agentArchitecture,

  };

  try {
    const response = await fetch('https://community.webamon.co.uk/analyst/profile', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (response.ok) {
      const data = await response.json();
      console.log('Configuration saved successfully:', data);
      alert('Configuration saved successfully.');
      // Optionally refresh the integrations list here if needed
    } else {
      console.error('Failed to save configuration:', response.statusText);
      alert('Failed to save configuration. Please try again.');
    }
  } catch (error) {
    console.error('Error saving configuration:', error);
    alert('An error occurred while saving the configuration.');
  }

  onClose(); // Close the dialog
};


  return (
   <Dialog
     open={open}
     onClose={onClose}
     maxWidth="md"
     fullWidth
     PaperProps={{
       style: {
         backgroundColor: '#191b2d',
         color: '#ffffff',
         display: 'flex',
         flexDirection: 'column',
         height: '85vh', // Set a fixed height for the dialog
         maxHeight: '85vh', // Ensure it doesn’t exceed this height
       },
     }}
   >
     <DialogTitle sx={{ color: '#56CCF2', fontSize: '1.4rem', marginBottom: '16px' }}>
       Configure LLM Settings - Dev in progress, keep checking back
         <IconButton onClick={toggleEditMode} sx={{ color: '#56CCF2', marginLeft: '10px' }}>
                 <EditIcon />
               </IconButton>
     </DialogTitle>
     <DialogContent
       sx={{
         display: 'flex',
         justifyContent: 'space-between',
         gap: '16px',
         overflowY: 'auto', // Enable scrolling for overflowing content
         flex: 1, // Ensures it takes available space within the fixed height
       }}
     >

<Box
  sx={{
    overflowY: 'auto',
    padding: '16px',
    backgroundColor: '#2b2f44',
    borderRadius: '8px',
    flex: 1,
    maxHeight: 'calc(80vh - 160px)',
  }}
>
  {selectedIntegration === 'new_profile' && (
    <>
      <FormControl fullWidth sx={{ marginBottom: '16px' }}>
        <InputLabel
          sx={{
            color: '#ffffff',
            '&.Mui-focused': { color: '#4a90e2' },
          }}
        >
          API Type
        </InputLabel>
        <Select
          value={apiType}
          onChange={(e) => setApiType(e.target.value)}
          sx={{
            '& .MuiSelect-select': { backgroundColor: '#2b2f44', color: '#ffffff' },
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#56CCF2' },
              '&:hover fieldset': { borderColor: '#4a90e2' },
              '&.Mui-focused fieldset': { borderColor: '#4a90e2' },
            },
          }}
        >
          <MenuItem value="webamon">Webamon</MenuItem>
          <MenuItem value="openai">OpenAI</MenuItem>
          <MenuItem value="mistral">Mistral</MenuItem>
          <MenuItem value="azure">Azure</MenuItem>
          <MenuItem value="groq">Groq</MenuItem>
          <MenuItem value="ollama">Ollama</MenuItem>
          <MenuItem value="bedrock">AWS Bedrock</MenuItem>
        </Select>
      </FormControl>
      {renderFieldsForApiType()}
    </>
  )}
  {Object.keys(dynamicFields).map((field) => (
    <TextField
      key={field}
      label={field.replace('_', ' ').toUpperCase()}
      variant="outlined"
      fullWidth
      value={dynamicFields[field]}
      onChange={(e) => handleDynamicFieldChange(field, e.target.value)}
      disabled={!isEditable} // Disable when not editable
      InputLabelProps={{
        style: { color: '#ffffff' }, // Ensure label text is always white
      }}
      InputProps={{
        style: {
          color: '#ffffff', // Ensure text color is white
          backgroundColor: isEditable ? '#2b2f44' : '#1f2235', // Different background for editable state
        },
      }}
      sx={{
        marginBottom: '16px',
        '& .MuiOutlinedInput-root fieldset': { borderColor: '#56CCF2' },
        '&:hover .MuiOutlinedInput-root fieldset': { borderColor: '#4a90e2' },
        '&.Mui-focused .MuiOutlinedInput-root fieldset': { borderColor: '#4a90e2' },
        '& .Mui-disabled': {
          color: '#bdbdbd', // Light gray text for disabled fields
          '-webkit-text-fill-color': '#bdbdbd', // Fix for Safari browsers
        },
      }}
    />
  ))}



                <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              marginBottom: '16px',
                              backgroundColor: '#1f2235',
                              padding: '12px 16px',
                              borderRadius: '8px',
                            }}
                          >
                            <Typography sx={{ color: '#ffffff', fontSize: '1rem' }}>Enable Memory</Typography>
                            <Switch
                              checked={memoryEnabled}
                              onChange={handleMemoryToggle}
                              sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                  color: '#4a90e2',
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                  backgroundColor: '#4a90e2',
                                },
                              }}
                            />
                          </Box>
                          <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'flex-end',
                                  marginBottom: '16px',
                                }}
                              >
                                <Button

                               sx={{ color: '#ffffff', '&:hover': { backgroundColor: '#343b6f' }, marginRight: '10px' }}
                                >
                                  Reset
                                </Button>
                                       <Button onClick={handleSave} sx={{ color: '#56CCF2', '&:hover': { backgroundColor: '#4a90e2' } }}>
                                         Save
                                       </Button>
                              </Box>
       </Box>

       <Box
         sx={{
           width: '250px',
           backgroundColor: '#2b2f44',
           padding: '16px',
           borderRadius: '8px',
           overflowY: 'auto',
           maxHeight: 'calc(80vh - 160px)', // Adjust for consistent height
         }}
       >
         <Typography sx={{ color: '#ffffff', fontSize: '1.2rem', marginBottom: '16px' }}>
           Current LLM Profiles
         </Typography>
{integrations.map((integration, index) => (
  <Box
    key={index}
    sx={{
      position: 'relative', // Enable positioning for the delete icon
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '8px',
      backgroundColor: selectedIntegration === integration.llm_profile_name ? '#4a90e2' : '#343b6f',
      color: '#ffffff',
      padding: '8px',
      borderRadius: '4px',
    }}
  >
    <Button
      onClick={() => handleIntegrationClick(integration)}
      sx={{
        flex: 1, // Take available space
        textAlign: 'left',
        color: '#ffffff',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      }}
    >
      {integration.llm_profile_name}
    </Button>
{integration.llm_profile_name !== 'webamon_assistant' && (
  <IconButton
    onClick={() => {
      const confirmation = window.confirm(`Are you sure you wish to delete ${integration.llm_profile_name}?`);
      if (confirmation) {
        console.log(`Delete ${integration.llm_profile_name}`);
        // Add your delete logic here
      }
    }}
    sx={{
      color: '#ffffff',
      position: 'absolute',
      top: '4px',
      right: '4px',
    }}
  >
    <DeleteIcon />
  </IconButton>
)}

  </Box>

))}

 <Box
    onClick={() => {
      setSelectedIntegration('new_profile'); // Set selected profile to 'new_profile'
      setName(''); // Clear form values
      setEndpoint('');
      setApiKey('');
      setSystemPrompt('');
      setDynamicFields({});
      setIsEditable(true); // Enable edit mode for the new profile
    }}
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#4a90e2',
      color: '#ffffff',
      padding: '8px',
      borderRadius: '4px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#357ab8',
      },
      marginTop: '16px',
    }}
  >
    + New Profile
  </Box>

       </Box>

     </DialogContent>
     <DialogActions sx={{ padding: '0 20px 20px 20px', backgroundColor: '#1f2235' }}>
       <Button onClick={onClose} sx={{ color: '#ffffff', '&:hover': { backgroundColor: '#343b6f' } }}>
         Close
       </Button>
     </DialogActions>
   </Dialog>

  );
};

export default ThreatConfigDialog;
