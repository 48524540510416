const apiTypeFieldsMapping = {
  azure: [
    "Model",
    "API Key",
    "API Type",
    "Base URL",
    "API Version",
  ],
  openai: [
    "Model",
    "API Key",
    "API Type",
    "Base URL",
  ],
  bedrock: [
    "Model",
    "API Type",
    "AWS Region",
    "AWS Access Key",
    "AWS Secret Key",
    "Price",
    "Temperature",
    "Cache Seed",
  ],
  groq: [
    "Model",
    "API Key",
    "API Type",
    "Frequency Penalty",
    "Max Tokens",
    "Presence Penalty",
    "Seed",
    "Temperature",
    "Top P",
    "Stop",
  ],
  mistral: [
    "Model",
    "API Key",
    "API Type",
    "Temperature",
    "Top P",
    "Max Tokens",
    "Safe Prompt",
    "Random Seed",
  ],
  ollama: [
    "Model",
    "API Type",
    "Num Predict",
    "Repeat Penalty",
    "Seed",
    "Stream",
    "Temperature",
    "Top K",
    "Top P",
  ],
  webamon: [
    "API Type",
  ],
};

export default apiTypeFieldsMapping;
