import React, { useState } from 'react';
import { Box, Typography, Tabs, Tab, Table, TableBody, TableCell, IconButton, TableContainer, TableHead, TableRow, Paper, Card, CardContent, Accordion, AccordionSummary, AccordionDetails, Dialog, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import ReactJson from 'react-json-view';
import Domains from './sandbox/Domains';
import RequestTable from './sandbox/RequestTable';
import ServerInfo from './sandbox/ServerInfo';
import ThreatAIChat from './ThreatAIChat';

const countryCodeToFlag = (countryCode) => {
  return countryCode
    .toUpperCase()
    .replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt()));
};



const ReportComponent = ({ results, screenshot, onClose }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isImageOpen, setIsImageOpen] = useState(false); // State to control image modal
  const parsedResults = typeof results === 'string' ? JSON.parse(results) : results;
  const dataToDisplay = parsedResults?.report || {};
  const serverList = dataToDisplay.server || [];
  const requestList = dataToDisplay.request || [];
  const resourceList = dataToDisplay.resource || [];
  const meta = dataToDisplay.meta || {};
  const pageScripts = dataToDisplay.page_scripts || [];
  const pageLinks = dataToDisplay.page_links || [];
  const technologyList = dataToDisplay.technology || [];
  const certificates = dataToDisplay.certificate || [];
  const pageTitle = dataToDisplay.page_title || 'N/A';  const domainList = dataToDisplay.domain || [];
    const [isDialogOpen, setIsDialogOpen] = useState(true); // Control the main dialog open state
  const cookies = dataToDisplay.cookie || [];
  const [isChatOpen, setIsChatOpen] = useState(false);
    const reportId = dataToDisplay?.meta?.report_id || null;
  const uniqueFlags = Array.from(new Set(
    serverList
      .filter((server) => server.country && server.country.iso)
      .map((server) => countryCodeToFlag(server.country.iso))
  ));

  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the main dialog
    setIsImageOpen(false); // Close the image dialog if open
    onClose(); // Call the parent onClose to fully close
  };


  const handleImageClick = () => {
    setIsImageOpen(true);
  };

  const handleCloseImage = () => {
    setIsImageOpen(false);
  };

  const handleCopy = (copy) => {
    const val = copy.src;
    const copyText = typeof val === 'string' ? val : JSON.stringify(val, null, 2);
    navigator.clipboard.writeText(copyText).catch((err) => {
      console.error('Failed to copy: ', err);
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <Dialog
          open={isDialogOpen}
          onClose={handleCloseDialog}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            style: { borderRadius: '12px', backgroundColor: '#1c1f2e' },
          }}
        >
          <DialogContent sx={{ padding: 0, overflow: 'hidden', display: 'flex', flexDirection: 'column', height: '90vh' }}>
            {/* Close Button */}
            <Box sx={{ position: 'absolute', top: '8px', right: '8px', zIndex: 1 }}>
              <IconButton onClick={handleCloseDialog} sx={{ color: '#ffffff' }}>
                <CloseIcon />
              </IconButton>
            </Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          sx={{
            backgroundColor: '#2b2e44',
            borderRadius: '10px 10px 0 0',
            '& .MuiTab-root': {
              color: '#fff',
              fontSize: '1.1rem',
              padding: '1rem',
              transition: 'color 0.2s ease-in-out',
              '&:hover': {
                color: '#4ca1af',
              },
              '&.Mui-selected': {
                color: '#4ca1af',
                fontWeight: 'bold',
              },
            },
          }}
        >
          <Tab label="Overview" />
          <Tab label="Requests" />
          <Tab label="Domains" />
          <Tab label="Servers" />
          <Tab label="Threat AI" />
        </Tabs>
<Box sx={{ overflowY: 'auto', padding: '2rem', flex: 1 }}>
      {activeTab === 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2rem', position: 'relative' }}>
<Typography variant="h4" sx={{ color: '#4ca1af', fontWeight: 'bold', textAlign: 'center', marginBottom: '1rem'}}>
                {pageTitle}
              </Typography>                     <Box sx={{ display: 'flex', gap: '1.5rem', flexWrap: 'wrap' }}>
                       {/* Cards for Key Metrics */}
                       {[
                         { label: 'Requests', value: meta.request_count },
                         { label: 'Scripts', value: meta.script_count },
                         { label: 'Domains', value: meta.domain_count },
                         { label: 'Threat Score', value: meta.risk_score },
                       ].map(({ label, value }) => (
                         <Card key={label} sx={{ backgroundColor: '#2b2e44', color: '#fff', minWidth: 120, borderRadius: '10px' }}>
                           <CardContent>
                             <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#4ca1af' }}>{label}</Typography>
                             <Typography variant="h6">{value || 'N/A'}</Typography>
                           </CardContent>
                         </Card>
                       ))}
                     </Box>
          <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: '1.5rem' }}>


            {[
                          { label: 'Report ID', value: meta.report_id },


              { label: 'Submission UTC', value: meta.submission_utc },
              { label: 'Scan Time', value: dataToDisplay.scan_time },
              { label: 'Scan Status', value: dataToDisplay.scan_status },
              { label: 'Scan Errors', value: dataToDisplay.errors },
              { label: 'Submission', value: meta.submission },
              { label: 'Tag', value: dataToDisplay.tag },
            ].map(({ label, value }) => (
              <Box key={label} sx={{ display: 'flex', gap: '0.75rem', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#4ca1af' }}>{label}:</Typography>
                <Typography sx={{ fontSize: '1.3rem', color: '#e3e5e8' }}>{value || 'N/A'}</Typography>
              </Box>
            ))}

               {/* Display aggregated country flags */}
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: '1.2rem', color: '#4ca1af' }}>Countries:</Typography>
                        <Box sx={{ fontSize: '1.5rem', display: 'flex', gap: '8px' }}>
                          {uniqueFlags.map((flag, index) => (
                            <span key={index}>{flag}</span>
                          ))}
                        </Box>
                      </Box>

            {[{ label: 'Page Scripts', content: pageScripts }, { label: 'Page Links', content: pageLinks }, { label: 'Technology', content: technologyList }].map((section) => (
         <Accordion key={section.label} sx={{ backgroundColor: '#2b2e44', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#4ca1af' }} />} sx={{ backgroundColor: '#32354a' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#4ca1af' }}>{section.label}</Typography>
                      <Typography variant="subtitle2" sx={{ color: '#4ca1af' }}>
                        {section.content.length} items
                      </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: '#1c1f2e', color: '#4ca1af' }}>
                  <Box component="ul" sx={{ marginLeft: '1.5rem', listStyleType: 'disc' }}>
                    {section.content.length > 0 ? (
                      section.content.map((item, index) => (
                        <Box component="li" key={index} sx={{ marginBottom: '0.75rem', fontSize: '1rem', color: '#e3e5e8' }}>
                          {section.label === 'Technology' ? (
                            <>
                              <strong>{item.name}</strong> -{' '}
                              <a href={item.url} style={{ color: '#4ca1af', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">
                                {item.url}
                              </a>
                            </>
                          ) : (
                            item
                          )}
                        </Box>
                      ))
                    ) : (
                      <Box component="li" sx={{ color: 'gray', fontSize: '1rem' }}>No {section.label.toLowerCase()} available</Box>
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}

            {/* Certificates Accordion */}
 <Accordion sx={{ backgroundColor: '#2b2e44', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#4ca1af' }} />} sx={{ backgroundColor: '#32354a' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#4ca1af' }}>Certificates</Typography>
                    <Typography variant="subtitle2" sx={{ color: '#4ca1af' }}>
                      {certificates.length} items
                    </Typography>
                  </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#1c1f2e' }}>
                <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto', overflowX: 'auto', backgroundColor: '#2b2e44', color: '#fff', borderRadius: '8px' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Issuer</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Subject Name</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Protocol</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Valid From</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Valid To</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>SAN List</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {certificates.map((cert, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cert.issuer || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cert.subject_name || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cert.protocol || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cert.valid_from_utc || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cert.valid_to_utc || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8', whiteSpace: 'nowrap' }}>{cert.san_list ? cert.san_list.join(', ') : 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            {/* Cookies Accordion */}
             <Accordion sx={{ backgroundColor: '#2b2e44', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)' }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: '#4ca1af' }} />} sx={{ backgroundColor: '#32354a' }}>
                              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', color: '#4ca1af' }}>Cookies</Typography>
                                <Typography variant="subtitle2" sx={{ color: '#4ca1af' }}>
                                  {cookies.length} items
                                </Typography>
                              </Box>
                            </AccordionSummary>
              <AccordionDetails sx={{ backgroundColor: '#1c1f2e' }}>
                <TableContainer component={Paper} sx={{ maxHeight: 400, overflowY: 'auto', overflowX: 'auto', backgroundColor: '#2b2e44', color: '#fff', borderRadius: '8px' }}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Domain</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Expiry</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Name</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Path</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Secure</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>HTTP Only</TableCell>
                        <TableCell sx={{ color: '#fff', backgroundColor: '#2d2f45', fontSize: '1.1rem' }}>Same Site</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {cookies.map((cookie, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cookie.domain || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{new Date(cookie.expiry * 1000).toLocaleString() || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cookie.name || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cookie.path || 'N/A'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cookie.secure ? 'Yes' : 'No'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cookie.http_only ? 'Yes' : 'No'}</TableCell>
                          <TableCell sx={{ color: '#e3e5e8' }}>{cookie.same_site || 'N/A'}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          </Box>

{screenshot && (
          <Box
            onClick={handleImageClick}
            sx={{
              position: 'absolute',
              top: '4rem',
              right: '2rem',
              width: '40%',
              maxWidth: '400px',
              borderRadius: '10px',
              overflow: 'hidden',
              border: '2px solid #4ca1af',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
              marginTop: '2rem',
              cursor: 'pointer',
            }}
          >
            <img src={screenshot} alt="Screenshot Thumbnail" style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
          </Box>
        )}

          {/* Dialog for full-sized image */}
          <Dialog open={isImageOpen} onClose={handleCloseImage} maxWidth="lg">
            <DialogContent sx={{ padding: 0, backgroundColor: '#1c1f2e' }}>
              <img src={screenshot} alt="Full-size Screenshot" style={{ width: '100%', height: 'auto', borderRadius: '8px' }} />
            </DialogContent>
          </Dialog>
        </Box>
      )}


          {activeTab === 1 && <RequestTable requestList={requestList} />}
          {activeTab === 2 && <Domains domainList={domainList} />}
          {activeTab === 3 && <ServerInfo serverList={serverList} />}
          {activeTab === 4 && <ThreatAIChat open initialData={reportId} onClose={() => setActiveTab(0)} />}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ReportComponent;
