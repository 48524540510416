import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';
import './Home.css'; // Make sure to add responsive CSS rules here
import ScanPage from '../components/sandbox/sandbox.js';

const Home = () => {


  return (
    <div className="home-container">
      <Box className="scan-box">
        <ScanPage />
      </Box>
    </div>
  );
};

export default Home;
